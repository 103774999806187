import { DOCUMENT } from '@angular/common'
import { Injectable, LOCALE_ID, inject } from '@angular/core'
import { SimpleStore } from '@core/store/simple-store'
import { TranslocoService } from '@jsverse/transloco'
import { HomeApiService } from '@main/home/services/home-api.service'
import { CookieService } from 'ngx-cookie-service'
import { map, take } from 'rxjs'
import { environment } from '@environment/environment';

export type AppState = {
    competitionCategories: string[]
    currency: string
    countries: { name: string; code: string }[]
    availableLanguages: Language[]
    selectedLanguage: Language | null
}

export interface Language {
    displayName: string
    locale: string
    shortName: string
}

export const initialState: AppState = {
    competitionCategories: [],
    currency: '',
    countries: [],
    availableLanguages: [],
    selectedLanguage: null,
}

const LANGUAGE_COOKIE_KEY = 'lang'

@Injectable({
    providedIn: 'root',
})
export class AppStateService extends SimpleStore<AppState> {
    private document = inject(DOCUMENT)

    private homeApiService = inject(HomeApiService)
    private translocoService = inject(TranslocoService)
    private cookieService = inject(CookieService)
    private locale = inject(LOCALE_ID)

    constructor() {
        super(initialState)
    }

    init() {
        return this.loadReferenceData()
    }

    changeLanguage(selectedLang: Language) {
        this.translocoService
            .load(selectedLang.locale)
            .pipe(take(1))
            .subscribe(() => {
                this.setState({ selectedLanguage: selectedLang })
                this.cookieService.set(LANGUAGE_COOKIE_KEY, JSON.stringify(selectedLang))
                this.translocoService.setActiveLang(selectedLang.locale)
                this.document.documentElement.lang = selectedLang.locale
            })
    }

    private loadReferenceData() {
        return this.homeApiService.getReferenceData().pipe(
            map((data) => {
                const availableLanguages = data['available-languages']
                const preferredLanguage = this.getPreferredLanguage(availableLanguages)
                this.changeLanguage(preferredLanguage)
                return this.setState({
                    competitionCategories: data['competition-category'],
                    currency: data['product.defaultCurrency'],
                    countries: data['countries'],
                    availableLanguages,
                })
            }),
        )
    }

    private getPreferredLanguage(availableLanguages: Language[]): Language {
        const stored = this.cookieService.get(LANGUAGE_COOKIE_KEY)
        return stored ?
            availableLanguages.find((lang) => lang.locale === stored) || availableLanguages[0] :
                  availableLanguages[0]
    }
}
