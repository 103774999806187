<div class="bg-primary-400" *transloco="let t">
    <div class="mx-auto max-w-screen-lg">
        <div class="px-4 py-4 lg:px-0">
            <div class="flex flex-row items-center justify-between text-white">
                <div class="flex items-center whitespace-nowrap">
                    <a class="cursor-auto">
                        <img
                            alt="Quizzaro"
                            class="h-12 w-auto md:h-16 lg:-ml-4"
                            src="/assets/image/logo.png"
                        />
                    </a>
                </div>
                <div
                    class="flex items-center justify-start gap-4 border border-secondary-200 px-4 py-2"
                >
                    <i class="fa-solid fa-lock text-xl"></i>
                    <div class="space-y-1">
                        <h2 class="text-xs font-semibold uppercase tracking-wider md:text-sm">
                            {{ t('checkout.safeAndSecure') }}
                        </h2>
                        <p class="text-xs font-medium">{{ t('checkout.sslEncryption') }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
