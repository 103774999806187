import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Params } from '@angular/router'
import { Competition } from '@core/models'
import { ApiResponse } from '@core/models/api-response'
import { ErrorHandlerService } from '@core/services/error-handler.service'
import { LoggerService } from '@core/services/logger.service'
import { environment } from '@environment/environment'
import { Observable, catchError, map, tap, throwError } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class CompetitionApiService {
    private http = inject(HttpClient)
    private loggerService = inject(LoggerService)
    private errorHandlerService = inject(ErrorHandlerService)

    private apiUrl = `${environment.serviceUrl}/competitions`

    list(page = 1, pageSize = 10): Observable<ApiResponse<Competition[]>> {
        const params: Params = {
            page,
            pageSize,
            sortBy: 'name',
            sortDirection: 'asc',
        }
        return this.http.get<ApiResponse<Competition[]>>(this.apiUrl, { params }).pipe(
            map((response) => this.mapResponse(response)),
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Competitions', err)
            }),
        )
    }

    getMyCompetitions(): Observable<Competition[]> {
        return this.http.get<Competition[]>(`${environment.serviceUrl}/my-competitions`).pipe(
            map((response) => response.map((x) => this.getCompetitionWithImage(x))),
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Competitions', err)
            }),
        )
    }

    getCompetitionById(id: string): Observable<Competition> {
        return this.http.get<Competition>(`${this.apiUrl}/${id}`).pipe(
            map((response) => this.getCompetitionWithImage(response)),
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Competitions', err)
            }),
        )
    }

    getActive(
        category: string | null,
        pageSize: number,
        pageNumber: number,
    ): Observable<ApiResponse<Competition[]>> {
        const params: Params = {}
        if (category != null) {
            params['category'] = category.toUpperCase()
        }
        params['pageNumber'] = pageNumber
        params['pageSize'] = pageSize

        return this.http.get<ApiResponse<Competition[]>>(`${this.apiUrl}/active`, { params }).pipe(
            map((response) => this.mapResponse(response)),
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Competitions', err)
            }),
        )
    }

    getQuizBySlug(slug: string): Observable<Competition> {
        return this.http.get<Competition>(`${this.apiUrl}/slug/${slug}`).pipe(
            map((response) => this.getCompetitionWithImage(response)),
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Competitions', err)
            }),
        )
    }

    getFeaturedCompetitions(code: string): Observable<Competition[]> {
        return this.http
            .get<{ name: string; competitions: Competition[] }>(`${this.apiUrl}/lists/${code}`)
            .pipe(
                map((response) => {
                    return response.competitions.map((x) => this.getCompetitionWithImage(x))
                }),
                catchError((err: HttpErrorResponse) => {
                    return this.errorHandlerService.handleError('Competitions', err)
                }),
            )
    }

    private mapResponse(response: ApiResponse<Competition[]>): ApiResponse<Competition[]> {
        return {
            ...response,
            items: response.items.map((x) => this.getCompetitionWithImage(x)),
        }
    }

    private getCompetitionWithImage(competition: Competition): Competition {
        return {
            ...competition,
            imageUrl: `${environment.serviceUrl}/files/${competition.displayImage.id}`,
        }
    }
}
