import { Routes } from '@angular/router'
import { IsAuthenticatedGuard } from '@core/auth/IsAuthenticatedGuard'
import { IsNotAuthenticatedGuard } from '@core/auth/IsNotAuthenticatedGuard'
import { leavePageGuard } from '@core/guards/leave-page-guard.service'
import { resolveDataSync } from '@core/services/local-storage.service'
import { PageLayout } from '@main/layout/page-layout.enum'
import { setLayout } from '@main/layout/set-layout.resolver'

export const APP_ROUTES: Routes = [
    {
        path: '',
        loadComponent: () => import('@pages/home/home.component').then((m) => m.HomeComponent),
        pathMatch: 'full',
        resolve: { layout: setLayout(PageLayout.Blank) },
    },
    // active quizzes page
    {
        path: 'quizzes/:lang',
        loadComponent: () =>
            import('@pages/competitions/competitions.component').then(
                (m) => m.CompetitionsComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'featured/:code',
        loadComponent: () =>
            import('@pages/competition-list/competition-list.component').then(
                (m) => m.CompetitionListComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    // quiz details page
    {
        path: 'quizzes/:lang/:slug',
        loadComponent: () =>
            import('@pages/competition-details/competition-details.component').then(
                (m) => m.CompetitionDetailsComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
    },

    {
        path: 'checkout',
        loadComponent: () =>
            import('@pages/checkout/checkout.component').then((m) => m.CheckoutComponent),
        resolve: { layout: setLayout(PageLayout.Checkout) },
    },
    // after checkout thank you or failure page
    {
        path: 'order-details/:id',
        loadComponent: () =>
            import('@pages/order-details/order-details.component').then(
                (m) => m.OrderDetailsComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    // game instruction page
    {
        path: 'game/:ticketId',
        loadComponent: () =>
            import('@pages/game-instructions/game-instructions.component').then(
                (m) => m.GameInstructionsComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
        canActivate: [IsAuthenticatedGuard],
    },
    // game play screen
    {
        path: 'game/:playId/play',
        loadComponent: () =>
            import('@pages/game-play/game-play.component').then((m) => m.GamePlayComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
        canActivate: [IsAuthenticatedGuard],
        canDeactivate: [leavePageGuard],
    },
    {
        path: 'quiz/:slug/rank',
        loadComponent: () =>
            import('@pages/game-rank/game-rank.component').then((m) => m.GameRankComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'game-center',
        loadComponent: () =>
            import('@pages/game-center/game-center.component').then((m) => m.GameCenterComponent),
        canActivate: [IsAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'game-center/:ticketId',
        loadComponent: () =>
            import('@pages/competition-rank/competition-rank.component').then(
                (m) => m.CompetitionRankComponent,
            ),
        canActivate: [IsAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'wallet',
        loadComponent: () =>
            import('@pages/wallet-page/wallet-page.component').then((m) => m.WalletPageComponent),
        // canActivate: [IsAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Default) },
        children: [
            {
                path: 'transactions',
                loadComponent: () =>
                    import('@pages/wallet-page/transactions/transactions.component').then(
                        (m) => m.TransactionsComponent,
                    ),
            },
            {
                path: 'payout',
                loadComponent: () =>
                    import('@pages/wallet-page/payout/payout.component').then(
                        (m) => m.PayoutComponent,
                    ),
            },
        ],
    },
    {
        path: 'payout',
        loadComponent: () =>
            import('@pages/payout/payout.component').then((m) => m.PayoutComponent),
    },
    {
        path: 'payment',
        loadComponent: () =>
            import('@pages/payment/payment.component').then((m) => m.PaymentComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'profile',
        loadComponent: () =>
            import('@pages/auth/profile/profile.component').then((m) => m.ProfileComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'change-profile',
        loadComponent: () =>
            import('@pages/auth/change-profile/change-profile.component').then(
                (m) => m.ChangeProfileComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'change-password',
        loadComponent: () =>
            import('@pages/auth/change-password/change-password.component').then(
                (m) => m.ChangePasswordComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'auth/authorize',
        loadComponent: () =>
            import('@pages/auth/authorize/authorize.component').then((m) => m.AuthorizeComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'verify-email',
        loadComponent: () =>
            import('@pages/auth/verify-email/verify-email.component').then(
                (m) => m.VerifyEmailComponent,
            ),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Center) },
    },
    {
        path: 'auth/login',
        loadComponent: () =>
            import('@pages/auth/login/login.component').then((m) => m.LoginComponent),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'auth/register',
        loadComponent: () =>
            import('@pages/auth/register/register.component').then((m) => m.RegisterComponent),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'auth/account-created',
        loadComponent: () =>
            import('@pages/auth/registration-successful/registration-successful.component').then(
                (m) => m.RegistrationSuccessfulComponent,
            ),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Center) },
    },
    {
        path: 'auth/forgot-password',
        loadComponent: () =>
            import('@pages/auth/forgot-password/forgot-password.component').then(
                (m) => m.ForgotPasswordComponent,
            ),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Center) },
    },
    {
        path: 'auth/reset-password',
        loadComponent: () =>
            import('@pages/auth/reset-password/reset-password.component').then(
                (m) => m.ResetPasswordComponent,
            ),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Center) },
    },
    {
        path: 'auth/logout',
        loadComponent: () =>
            import('@pages/auth/logout/logout.component').then((m) => m.LogoutComponent),
        resolve: { layout: setLayout(PageLayout.Center) },
        canActivate: [IsAuthenticatedGuard],
    },
    {
        path: 'auth/reset-password-successful',
        loadComponent: () =>
            import(
                '@pages/auth/reset-password-successful/reset-password-successful.component'
            ).then((m) => m.ResetPasswordSuccessfulComponent),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Center) },
    },
    {
        path: 'auth/google',
        loadComponent: () =>
            import('@pages/auth/google-auth/google-auth.component').then(
                (m) => m.GoogleAuthComponent,
            ),
        canActivate: [IsNotAuthenticatedGuard],
        resolve: { layout: setLayout(PageLayout.Center) },
    },
    {
        path: 'page/:lang/:slug',
        loadComponent: () =>
            import('@pages/generic-page/generic-page.component').then(
                (m) => m.GenericPageComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'terms-of-use',
        loadComponent: () =>
            import('@pages/terms-of-use/terms-of-use.component').then((m) => m.TermsOfUseComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'privacy-policy',
        loadComponent: () =>
            import('@pages/privacy-policy/privacy-policy.component').then(
                (m) => m.PrivacyPolicyComponent,
            ),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'about-us',
        loadComponent: () =>
            import('@pages/about-us/about-us.component').then((m) => m.AboutUsComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: 'flajer',
        loadComponent: () =>
            import('@pages/page-tracking/page-tracking.component').then(
                (m) => m.PageTrackingComponent,
            ),
        resolve: {
            layout: setLayout(PageLayout.Default),
            lang: resolveDataSync('sr_RS'),
            redirect: resolveDataSync(''),
            trackEvent: resolveDataSync('flajer'),
        },
    },
    {
        path: 'rs',
        loadComponent: () =>
            import('@pages/page-tracking/page-tracking.component').then(
                (m) => m.PageTrackingComponent,
            ),
        resolve: {
            layout: setLayout(PageLayout.Default),
            lang: resolveDataSync('sr_RS'),
            redirect: resolveDataSync(''),
        },
    },
    {
        path: 'redirect/:path',
        loadComponent: () =>
            import('@pages/redirect/redirect.component').then((m) => m.RedirectComponent),
    },
    {
        path: 'not-found',
        loadComponent: () =>
            import('@pages/not-found/not-found.component').then((m) => m.NotFoundComponent),
        resolve: { layout: setLayout(PageLayout.Default) },
    },
    {
        path: '**',
        redirectTo: 'not-found',
    },
]
