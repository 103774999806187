<div class="bg-primary-400">
    <div
        (clickOutside)="onClickOutside()"
        *transloco="let t; currentLang as currentLang"
        appClickOutside
        class="mx-auto max-w-screen-lg"
    >
        <div class="px-4 py-4 lg:px-0">
            <div class="flex flex-row items-center justify-between text-white">
                <div class="flex items-center whitespace-nowrap">
                    <a [routerLink]="['/']" class="cursor-pointer">
                        <img
                            alt="Quizzaro"
                            class="h-12 w-auto md:h-16 lg:-ml-4"
                            src="/assets/image/logo.png"
                        />
                    </a>
                    <div class="hidden md:flex">
                        <div class="flex items-center">
                            <div>
                                <div
                                    (mouseleave)="isMenu1Open.set(false)"
                                    class="relative inline-block text-left"
                                >
                                    <div>
                                        <button
                                            (mouseenter)="isMenu1Open.set(true)"
                                            class="inline-flex w-full items-center px-4 py-2 text-white"
                                        >
                                            {{ t('header.categories') }}
                                            <app-dropdown-arrow
                                                [isOpen]="isMenu1Open()"
                                                class="ml-1"
                                            ></app-dropdown-arrow>
                                        </button>
                                    </div>

                                    @if (isMenu1Open()) {
                                        <div
                                            aria-labelledby="options-menu"
                                            aria-orientation="vertical"
                                            class="absolute z-50 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                        >
                                            <div class="py-1" role="none">
                                                <a
                                                    [routerLink]="['/quizzes', lang]"
                                                    class="block px-2 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 lg:px-4"
                                                    role="menuitem"
                                                >
                                                    {{ t('header.categories.all') }}
                                                </a>
                                                <!-- Dropdown items -->
                                                @for (item of categoryLinks; track item) {
                                                    <a
                                                        [queryParams]="{
                                                            category: item.toLowerCase(),
                                                        }"
                                                        [routerLink]="['/quizzes', lang]"
                                                        class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                        role="menuitem"
                                                    >
                                                        {{
                                                            t('header.categories.' + item)
                                                                | capitalize
                                                        }}
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>

                            <div>
                                <div
                                    (mouseleave)="isMenu2Open.set(false)"
                                    class="relative inline-block text-left"
                                >
                                    <div>
                                        <button
                                            (mouseenter)="isMenu2Open.set(true)"
                                            class="inline-flex w-full items-center px-2 py-2 text-white lg:px-4"
                                        >
                                            {{ t('header.topGames') }}
                                            <app-dropdown-arrow
                                                [isOpen]="isMenu2Open()"
                                                class="ml-1"
                                            ></app-dropdown-arrow>
                                        </button>
                                    </div>

                                    @if (isMenu2Open()) {
                                        <div
                                            aria-labelledby="options-menu"
                                            aria-orientation="vertical"
                                            class="absolute z-50 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                            role="menu"
                                        >
                                            <div class="py-1" role="none">
                                                <!-- Dropdown items -->
                                                @for (item of topGamesLinks; track item) {
                                                    <a
                                                        (click)="goToFeaturePage(item)"
                                                        class="block cursor-pointer px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                                                        role="menuitem"
                                                    >
                                                        {{ t('featured.' + item) }}
                                                    </a>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <nav class="hidden items-center gap-5 whitespace-nowrap text-base md:flex">
                    <!-- <app-language-selector></app-language-selector> -->
                    @defer {
                        @if (!loggedIn()) {
                            <div class="flex gap-4">
                                <button
                                    [routerLink]="['/auth', 'register']"
                                    class="cursor-pointer rounded bg-primary-100 px-4 py-2 text-primary-400 hover:bg-primary-50"
                                >
                                    {{ t('auth.getStarted') }}
                                </button>
                                <button
                                    [routerLink]="['/auth', 'login']"
                                    class="cursor-pointer rounded bg-primary-100 px-4 py-2 text-primary-400 hover:bg-primary-50"
                                >
                                    {{ t('auth.login') }}
                                </button>
                            </div>
                        }
                    }

                    <div
                        [routerLink]="['checkout']"
                        class="relative mr-4 flex cursor-pointer items-center"
                    >
                        <i class="fa-solid fa-cart-shopping fa-lg text-white"></i>
                        @if (cartService.cartItems().length) {
                            <span
                                class="absolute -right-4 -top-6 flex h-5 w-5 items-center justify-center rounded-full bg-white text-xs font-medium text-primary-400"
                                >{{ cartService.cartItems().length }}</span
                            >
                        }
                    </div>

                    <!-- after loggedIn -->
                    @if (loggedIn()) {
                        <div class="relative flex items-center gap-5">
                            <!-- Icon -->
                            <div>
                                <a [routerLink]="['game-center']"> {{ t('header.gameCenter') }} </a>
                            </div>

                            <app-profile-avatar
                                (click)="toggleProfileMenu()"
                                class="cursor-pointer"
                            ></app-profile-avatar>

                            @if (isProfileMenuOpen()) {
                                <div
                                    aria-labelledby="options-menu"
                                    aria-orientation="vertical"
                                    class="absolute top-12 z-50 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                                    role="menu"
                                >
                                    <div class="py-1" role="none">
                                        <a
                                            [routerLink]="['change-profile']"
                                            class="flex items-center justify-start gap-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            <i class="fa-solid fa-user"></i>
                                            <span>{{ t('header.profile') }}</span>
                                        </a>
                                        <a
                                            [routerLink]="['change-password']"
                                            class="flex items-center justify-start gap-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            <i class="fa-solid fa-key"></i>
                                            <span>{{ t('header.changePassword') }}</span>
                                        </a>
                                        <a
                                            (click)="authStateService.logout()"
                                            class="flex cursor-pointer items-center justify-start gap-3 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            <i class="fa-solid fa-arrow-right-from-bracket"></i>
                                            <span>{{ t('header.logout') }}</span>
                                        </a>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                </nav>

                <!-- Mobile Navbar Toggle Button. This will hide the button on medium and larger screens -->
                <div class="flex gap-4 md:hidden">
                    @if (loggedIn()) {
                        <div
                            [routerLink]="['game-center']"
                            class="mr-2 flex cursor-pointer items-center"
                        >
                            <i aria-hidden="true" class="fa fa-trophy fa-lg"></i>
                        </div>
                    }
                    <div
                        [routerLink]="['checkout']"
                        class="relative flex cursor-pointer items-center md:hidden"
                    >
                        <i class="fa-solid fa-cart-shopping fa-lg text-white"></i>
                        @if (cartService.cartItems().length) {
                            <span
                                class="absolute -right-4 top-1 flex h-5 w-5 items-center justify-center rounded-full bg-white text-xs font-medium text-primary-400"
                                >{{ cartService.cartItems().length }}</span
                            >
                        }
                    </div>
                    <button
                        (click)="toggleNavbar()"
                        class="inline-grid grid-cols-1 place-content-center rounded p-3 text-white hover:bg-primary-300 focus:outline-none md:hidden"
                    >
                        <svg
                            class="h-8 w-8"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                [class.block]="!isExpanded()"
                                [class.hidden]="isExpanded()"
                                d="M4 6h16M4 12h16m-7 6h7"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            ></path>
                            <path
                                [class.block]="isExpanded()"
                                [class.hidden]="!isExpanded()"
                                d="M6 18L18 6M6 6l12 12"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                            ></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>

        <!-- Mobile Navbar Dropdown -->
        @if (isExpanded()) {
            <div class="relative z-50 block text-base md:hidden">
                <app-header-mobile
                    class="absolute left-0 right-0 top-0 bg-primary-400 p-4 shadow-sm"
                ></app-header-mobile>
            </div>
        }
    </div>
</div>
