<div class="mt-16 bg-black py-16" *transloco="let t">
    <div class="ml-16 grid md:grid-cols-2">
        <div class="mb-6">
            <h1 class="text-xl font-bold text-white">{{ t('footer.followUs') }}</h1>
            <div class="mt-4 flex">
                <app-facebook-share></app-facebook-share>
                <app-twitter-link-icon></app-twitter-link-icon>
                <app-instagram-link-icon></app-instagram-link-icon>
                <app-youtube-link-icon></app-youtube-link-icon>
            </div>
        </div>

        <div>
            <h1 class="text-xl font-bold text-white">{{ t('footer.pages') }}</h1>
            <div>
                <ul class="mt-4 text-white">
                    <li class="mb-2">
                        <a href="">{{ t('footer.contact') }}</a>
                    </li>
                    <li class="mb-2">
                        <a href="">{{ t('footer.aboutUs') }}</a>
                    </li>
                    <li class="mb-2">
                        <a href="">{{ t('footer.privacyPolicy') }}</a>
                    </li>
                    <li class="mb-2">
                        <a href="">{{ t('footer.termsAndConditions') }}</a>
                    </li>
                    <li class="mb-2">
                        <a href="">{{ t('footer.faq') }}</a>
                    </li>
                    <app-language-selector class="mt-4"></app-language-selector>
                </ul>
            </div>
        </div>
    </div>
</div>
