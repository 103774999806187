import { Injectable, signal } from '@angular/core'
import { PageLayout } from './page-layout.enum'

@Injectable({
    providedIn: 'root',
})
export class PageLayoutService {
    currentLayout = signal<PageLayout>(PageLayout.Blank)

    setLayout(value: PageLayout) {
        this.currentLayout.set(value)
    }
}
