import { Component, OnInit, inject } from '@angular/core'
import { RouterOutlet } from '@angular/router'
import { AuthStateService } from '@core/auth/auth-state.service'
import { CookieService } from '@core/services/cookie.service'
import { LayoutCenteredComponent } from '@main/layout/layout-centered/layout-centered.component'
import { LayoutCheckoutComponent } from '@main/layout/layout-checkout/layout-checkout.component'
import { LayoutDefaultComponent } from '@main/layout/layout-default/layout-default.component'
import { PageLayout } from '@main/layout/page-layout.enum'
import { PageLayoutService } from '@main/layout/page-layout.service'
import { NgxSonnerToaster } from 'ngx-sonner'

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet,
        NgxSonnerToaster,
        LayoutDefaultComponent,
        LayoutCenteredComponent,
        LayoutCheckoutComponent,
    ],
})
export class AppComponent implements OnInit {
    private authStateService = inject(AuthStateService)
    layoutService = inject(PageLayoutService)

    readonly PageLayout = PageLayout

    ngOnInit(): void {
        this.authStateService.init()
    }
}
