import { Component, input } from '@angular/core'

@Component({
    selector: 'app-dropdown-arrow',
    standalone: true,
    imports: [],
    templateUrl: './dropdown-arrow.component.html',
    styleUrl: './dropdown-arrow.component.scss',
})
export class DropdownArrowComponent {
    isOpen = input(false)
}
