import { Component } from '@angular/core'
import { RouterLink } from '@angular/router'
import { ClickOutsideDirective } from '@core/directives/click-outside.directive'
import { CapitalizePipe } from '@core/pipes/capitalize.pipe'
import { TranslocoDirective } from '@jsverse/transloco'
import { HeaderDirective } from '@main/common/header/header.directive'
import { ProfileAvatarComponent } from '../../user/components/profile-avatar/profile-avatar.component'
import { ButtonComponent } from '../button/button.component'
import { DropdownArrowComponent } from '../dropdown-arrow/dropdown-arrow.component'
import { LanguageSelectorComponent } from '../language-selector/language-selector.component'
import { HeaderMobileComponent } from './header-mobile/header-mobile.component'

@Component({
    selector: 'app-header',
    standalone: true,
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [
        ButtonComponent,
        RouterLink,
        LanguageSelectorComponent,
        DropdownArrowComponent,
        ClickOutsideDirective,
        CapitalizePipe,
        TranslocoDirective,
        HeaderMobileComponent,
        ProfileAvatarComponent,
    ],
})
export class HeaderComponent extends HeaderDirective {}
