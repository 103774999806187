import { Component, Input } from '@angular/core'

@Component({
    selector: 'app-facebook-share',
    templateUrl: './facebook-link-icon.component.html',
    styleUrls: ['./facebook-link-icon.component.css'],
    standalone: true,
})
export class FacebookLinkIconComponent {
    @Input('fbPage')
    public fbPage = ''
}
