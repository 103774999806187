import { Injectable, computed, inject, signal } from '@angular/core'
import { Competition } from '@core/models'
import { LOCAL_STORAGE } from '@ng-web-apis/common'
import { flat } from 'radash'

@Injectable({
    providedIn: 'root',
})
export class CartService {
    private localStorage = inject(LOCAL_STORAGE)

    cartItems = signal<{ competition: Competition; qty: number }[]>([])
    cartTotal = computed(() => {
        return this.cartItems().reduce((acc, item) => {
            return acc + item.competition.buyIn.amount * item.qty
        }, 0)
    })

    constructor() {
        const fromLocal = this.localStorage?.getItem('cart')
        if (fromLocal) {
            this.cartItems.set(JSON.parse(fromLocal))
        }
    }

    getCartItemProductIds(): string[] {
        const productIds = flat(
            this.cartItems().map((x) => new Array(x.qty).fill(x.competition.productId.id)),
        )
        return productIds
    }

    add(competition: Competition, qty: number) {
        // if competition already exists, increase qty
        const existing = this.cartItems().find((item) => item.competition.id === competition.id)
        if (existing) {
            this.cartItems.set(
                this.cartItems().map((item) => {
                    if (item.competition.id === competition.id) {
                        return { competition, qty: item.qty + qty }
                    }
                    return item
                }),
            )
            this.saveInLocalStorage()
            return
        }
        // else add to items
        this.cartItems.set([...this.cartItems(), { competition, qty }])
        this.saveInLocalStorage()
    }

    remove(competition: Competition) {
        this.cartItems.set(this.cartItems().filter((x) => x.competition.id !== competition.id))
        this.saveInLocalStorage()
    }

    clearCart() {
        this.cartItems.set([])
        this.saveInLocalStorage()
    }

    private saveInLocalStorage() {
        localStorage.setItem('cart', JSON.stringify(this.cartItems()))
    }

    private removeFromLocalStorage() {
        this.localStorage?.removeItem('cart')
    }
}
