import { Pipe, PipeTransform, inject } from '@angular/core'
import {
    DomSanitizer,
    SafeHtml,
    SafeResourceUrl,
    SafeScript,
    SafeStyle,
    SafeUrl,
} from '@angular/platform-browser'

@Pipe({
    name: 'capitalize',
    standalone: true,
})
export class CapitalizePipe implements PipeTransform {
    protected sanitizer = inject(DomSanitizer)

    public transform(value: any): string {
        return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()
    }
}
