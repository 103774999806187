import { Directive, ElementRef, EventEmitter, HostListener, Output, inject } from '@angular/core'

@Directive({
    selector: '[appClickOutside]',
    standalone: true,
})
export class ClickOutsideDirective {
    private elementRef = inject(ElementRef)

    @Output() clickOutside = new EventEmitter<void>()

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: HTMLElement): void {
        const clickedInside = this.elementRef.nativeElement.contains(targetElement)
        if (!clickedInside) {
            this.clickOutside.emit()
        }
    }
}
