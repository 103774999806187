import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { LoginResponse } from '@core/models'
import { ErrorHandlerService } from '@core/services/error-handler.service'
import { environment } from '@environment/environment'
import { Observable, catchError } from 'rxjs'
import { PasswordRequirements } from './PasswordRequirements'

@Injectable({
    providedIn: 'root',
})
export class AuthApiService {
    http = inject(HttpClient)
    errorHandlerService = inject(ErrorHandlerService)

    authenticateWithRefreshToken(refreshToken: string): Observable<LoginResponse> {
        return this.http
            .post<LoginResponse>(`${environment.serviceUrl}/authentication/token/refresh`, {
                code: refreshToken,
            })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.errorHandlerService.handleError('Authentication', err)
                }),
            )
    }

    authenticateWithCode(refreshToken: string): Observable<LoginResponse> {
        return this.http
            .post<LoginResponse>(`${environment.serviceUrl}/authentication/token`, {
                code: refreshToken,
            })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.errorHandlerService.handleError('Authentication', err)
                }),
            )
    }

    authenticateWithGoogle(code: string): Observable<LoginResponse> {
        const url = `${environment.serviceUrl}/accounts/oauth/google/`
        return this.http.post<LoginResponse>(url, { code }).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    login(
        username: string,
        password: string,
        rememberMe: boolean,
        recaptchToken: string,
    ): Observable<LoginResponse> {
        const url = `${environment.serviceUrl}/accounts/authenticate`
        return this.http
            .post<LoginResponse>(url, {
                username,
                password,
                rememberMe,
                humanTest: recaptchToken,
            })
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.errorHandlerService.handleError('Authentication', err)
                }),
            )
    }

    authorize(clientId: string, redirectUrl: string): Observable<{ code: string }> {
        const url = `${environment.serviceUrl}/accounts/oauth/token?response_type=code&client_id=${clientId}&redirect_uri=${redirectUrl}`
        return this.http.post<{ code: string }>(url, {}).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    logout() {
        const url = `${environment.serviceUrl}/accounts/logout`
        return this.http.post(url, {}).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    getAvailableAuthMethods(): Observable<string[]> {
        return this.http.get<string[]>(`${environment.serviceUrl}/auth/available-methods`).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    register(username: string, password: string, reCaptchaToken: string) {
        const url = `${environment.serviceUrl}/accounts/`
        return this.http.post(url, { username, password, humanTest: reCaptchaToken }).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    verifyEmail(account: string, verificationCode: string) {
        const url = `${environment.serviceUrl}/accounts/${account}/verify/email`
        return this.http.post(url, { verificationCode }).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    resetPassword(username: string) {
        const url = `${environment.serviceUrl}/accounts/reset-password`
        return this.http.post(url, { username }).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    resetPasswordWithToken(newPassword: string, token: string) {
        const url = `${environment.serviceUrl}/accounts/reset-password`
        return this.http.put(url, { newPassword, token }).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    changePassword(newPassword: string, oldPassword: string, sub: string) {
        const url = `${environment.serviceUrl}/accounts/${sub}/password`
        return this.http.put(url, { newPassword, oldPassword }).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Authentication', err)
            }),
        )
    }

    getPasswordStrengthRequirement(): Observable<PasswordRequirements> {
        return this.http
            .get<PasswordRequirements>(
                `${environment.serviceUrl}/accounts/oauth/credentials/strength-info`,
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.errorHandlerService.handleError('Authentication', err)
                }),
            )
    }
}
