import { Injectable, inject } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    CanActivate,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { JwtService } from './jwt.service'
import { TokenStorageService } from './token-storage.service'

@Injectable({ providedIn: 'root' })
export class IsNotAuthenticatedGuard implements CanActivate {
    private jwtService = inject(JwtService)
    private tokenStorageService = inject(TokenStorageService)
    private router = inject(Router)

    private isLoggedIn() {
        const accessToken = this.tokenStorageService.getAccessToken()
        if (accessToken) {
            return !this.jwtService.isTokenExpired(accessToken)
        }
        return false
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Promise<boolean | UrlTree> {
        const isAuth = this.isLoggedIn()
        if (!isAuth) {
            return true
        }
        this.router.navigate(['/'])
        return false
    }
}
