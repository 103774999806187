import { CommonModule } from '@angular/common'
import { Component, inject } from '@angular/core'
import { CapitalizePipe } from '@core/pipes/capitalize.pipe'
import { AppStateService, Language } from '@core/states/app-state.service'

@Component({
    selector: 'app-language-selector',
    standalone: true,
    imports: [CommonModule, CapitalizePipe],
    templateUrl: './language-selector.component.html',
    styleUrl: './language-selector.component.scss',
})
export class LanguageSelectorComponent {
    appStateService = inject(AppStateService)

    changeLanguage(lang: Language) {
        this.appStateService.changeLanguage(lang)
    }
}
