import { DOCUMENT, isPlatformBrowser } from '@angular/common'
import { Injectable, PLATFORM_ID, inject } from '@angular/core'

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    private dom = inject(DOCUMENT)
    private platformId = inject(PLATFORM_ID)

    private isBrowser = false
    private _heap: any = {}
    private initialized = false
    private events: AnalyticsEvent[] = []
    constructor() {
        const platformId = this.platformId

        this.isBrowser = isPlatformBrowser(platformId)
        if (this.isBrowser) {
            window.addEventListener('load', (event) => {
                this.initialized = true
                this._heap = (window as any).heap
                this.events.forEach((event) => {
                    this.track(event)
                })
                this.events = []
            })
        }
    }

    identify(subject: string) {
        this.heap.identify(subject)
    }

    track(event: AnalyticsEvent) {
        this.heap.track(event.name, event.data)
    }

    get heap() {
        if (!this.initialized) {
            return {
                identify: () => {},
                track: (a: AnalyticsEvent) => {
                    this.events.push(a)
                },
            }
        }
        return this._heap
    }
}
export interface AnalyticsEvent {
    name: string
    data?: any | undefined
}
