import { HttpHandlerFn, HttpInterceptorFn, HttpRequest, HttpResponse } from '@angular/common/http'
import { inject } from '@angular/core'
import { environment } from '@environment/environment'
import { tap } from 'rxjs/operators'
import { AuthStateService } from './auth-state.service'
import { TokenStorageService } from './token-storage.service'

export const AuthInterceptorFn: HttpInterceptorFn = (
    request: HttpRequest<unknown>,
    next: HttpHandlerFn,
) => {
    const tokenStorageService = inject(TokenStorageService)
    const authStateService = inject(AuthStateService)
    const accessToken = tokenStorageService.getAccessToken()
    const isApiUrl = request.url.startsWith(environment.serviceUrl)
    let changedRequest = request

    if (accessToken && isApiUrl) {
        changedRequest = request.clone({
            setHeaders: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
    }

    return next(changedRequest).pipe(
        tap((event) => {
            if (event instanceof HttpResponse) {
                const newToken = event.headers.get('x-auth-token')
                if (newToken) {
                    authStateService.setStateAfterLogin(newToken)
                }
            }
        }),
    )
}
