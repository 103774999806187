import { Directive, EventEmitter, Input, Output, inject, signal } from '@angular/core'
import { NavigationEnd, Router } from '@angular/router'
import { AuthStateService } from '@core/auth/auth-state.service'
import { AppStateService } from '@core/states/app-state.service'
import { CartService } from '@main/cart/services/cart.service'
import { FeaturedCompetitionsStore } from '@main/competitions/states/featured.store'
import { Subscription } from 'rxjs'

@Directive({
    standalone: true,
})
export class HeaderDirective {
    private routerSubscription: Subscription
    private router = inject(Router)
    authStateService = inject(AuthStateService)
    appStateService = inject(AppStateService)
    featuredCompetitionStore = inject(FeaturedCompetitionsStore)
    cartService = inject(CartService)
    topGamesLinks = ['top-lists', 'new-games', 'trending-games']

    @Input() sidenavToggleVisible = true
    @Output() sidenavToggle = new EventEmitter<void>()

    isExpanded = signal(false)
    isMenu1Open = signal(false)
    isMenu2Open = signal(false)
    isProfileMenuOpen = signal(false)
    loggedIn = signal(false)
    lang = this.appStateService.getState().selectedLanguage?.shortName.toLowerCase() ?? 'en'

    constructor() {
        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isExpanded.set(false) // Close the mobile menu on navigation
            }
        })

        this.authStateService.select('isAuthenticated').subscribe({
            next: (loggedIn) => this.loggedIn.set(loggedIn),
        })
    }

    get categoryLinks() {
        return this.appStateService.getState().competitionCategories
    }

    ngOnDestroy() {
        if (this.routerSubscription) {
            this.routerSubscription.unsubscribe()
        }
    }

    toggle(): void {
        this.sidenavToggle.next()
    }

    toggleMenuOne() {
        this.isMenu1Open.update((x) => !x)
    }

    toggleMenuTwo() {
        this.isMenu2Open.update((x) => !x)
    }

    toggleNavbar() {
        this.isExpanded.update((x) => !x)
    }

    toggleProfileMenu() {
        this.isProfileMenuOpen.update((open) => !open)
    }

    onClickOutside() {
        this.isProfileMenuOpen.set(false)
        this.isMenu1Open.set(false)
        this.isMenu2Open.set(false)
    }

    goToFeaturePage(link: string) {
        const code = `${link}-${this.appStateService.getState().selectedLanguage?.shortName.toLowerCase() ?? 'en'}`
        this.featuredCompetitionStore.setCode(code)
        this.router.navigate(['/featured', code])
    }
}
