import { Component, OnDestroy, OnInit, inject, signal } from '@angular/core'
import { AuthStateService } from '@core/auth/auth-state.service'
import { environment } from '@environment/environment'
import { UserApiService } from '@main/user/services/user-api.service'
import { Subscription } from 'rxjs'

@Component({
    selector: 'app-profile-avatar',
    standalone: true,
    imports: [],
    templateUrl: './profile-avatar.component.html',
    styleUrl: './profile-avatar.component.scss',
})
export class ProfileAvatarComponent {
    auth = inject(AuthStateService)

    imageUrl = `${environment.serviceUrl}/accounts/${this.auth.getUserId()}/profile-picture`
}
