import { Component } from '@angular/core'
import { Footer1Component } from '@main/common/footer-1/footer-1.component'
import { HeaderComponent } from '@main/common/header/header.component'

@Component({
    selector: 'app-layout-default',
    standalone: true,
    templateUrl: './layout-default.component.html',
    styleUrls: ['./layout-default.component.scss'],
    imports: [Footer1Component, HeaderComponent],
})
export class LayoutDefaultComponent {}
