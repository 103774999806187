import { isEqual } from 'radash'
import {
    Observable,
    Subject,
    distinctUntilChanged,
    filter,
    map,
    share,
    shareReplay,
    takeUntil,
} from 'rxjs'
import { StateSubject } from './state-subject'

export class SimpleStore<T> extends StateSubject<T> {
    private unsubscriber: Subject<void>

    get state$(): Observable<T> {
        return this.value$.pipe(share(), takeUntil(this.unsubscriber))
    }

    constructor(value: T, unsubscriber?: Subject<void>) {
        super(value)
        this.unsubscriber = unsubscriber ?? new Subject<void>()
    }

    setState(value: Partial<T>, sideEffectFn?: () => void): void {
        this.next({ ...this.value, ...value })
        sideEffectFn?.()
    }

    getState(): T {
        return this.value
    }

    select<K extends keyof T>(key: K, filterFn?: (value: T[K]) => boolean): Observable<T[K]> {
        return this.value$.pipe(
            map((state) => state[key]),
            filter(filterFn ?? (() => true)),
            distinctUntilChanged((a, b) => isEqual(a, b)),
            shareReplay(1),
            takeUntil(this.unsubscriber),
        )
    }

    destroy(): void {
        this.unsubscriber.next()
        this.unsubscriber.complete()
    }
}
