import { Component } from '@angular/core'
import { CheckoutHeaderComponent } from '@main/common/checkout-header/checkout-header.component'
import { Footer1Component } from '@main/common/footer-1/footer-1.component'
import { HeaderComponent } from '@main/common/header/header.component'

@Component({
    selector: 'app-layout-checkout',
    standalone: true,
    imports: [Footer1Component, HeaderComponent, CheckoutHeaderComponent],
    templateUrl: './layout-checkout.component.html',
    styleUrl: './layout-checkout.component.scss',
})
export class LayoutCheckoutComponent {}
