import { computed, inject } from '@angular/core'
import { Competition } from '@core/models'
import { CompetitionApiService } from '@main/competitions/services/competition-api.service'
import { tapResponse } from '@ngrx/operators'
import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals'
import { rxMethod } from '@ngrx/signals/rxjs-interop'
import { distinctUntilChanged, pipe, switchMap, tap } from 'rxjs'

export type FeaturedCompetition = {
    code: string
    competitions: Competition[]
    loading: boolean
}

const initialState: FeaturedCompetition = {
    code: '',
    competitions: [],
    loading: false,
}

export const FeaturedCompetitionsStore = signalStore(
    { providedIn: 'root' },

    withState(initialState),

    withComputed((state) => ({
        langKey: computed(() => {
            const temp = state.code().split('-')
            temp.pop()
            return `featured.${temp.join('-')}`
        }),
    })),

    withMethods((state) => {
        const competitionApiService = inject(CompetitionApiService)

        return {
            setCode(code: string) {
                patchState(state, { code })
            },

            loadFeaturedCompetitions: rxMethod<string>(
                pipe(
                    distinctUntilChanged(),
                    tap((code) => patchState(state, { loading: true })),
                    switchMap((code) => {
                        return competitionApiService.getFeaturedCompetitions(code)
                    }),
                    tapResponse({
                        next: (value: Competition[]) => {
                            console.log('TCL: | withMethods | value:', value)
                            patchState(state, { competitions: value, loading: false })
                        },
                        error: (error) => {
                            patchState(state, { loading: false })
                        },
                    }),
                ),
            ),
        }
    }),
)
