import { HttpClient } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { Params } from '@angular/router'
import { environment } from '@environment/environment'
import { Observable, catchError, takeUntil, tap, throwError } from 'rxjs'

export interface LoggerData {
    accountId: 'string'
    id: 'string'
    identity: {
        id: 'string'
    }
    message: 'string'
    name: 'string'
    params: 'string'
    stack: 'string'
    time: 'string'
    timezoneOffset: 0
    type: 0
}

@Injectable({
    providedIn: 'root',
})
export class LoggerService {
    private http = inject(HttpClient)
    private apiUrl = `${environment.serviceUrl}/logs/`

    getLogs(pageSize?: number, pageNumber?: number, sort?: string): Observable<LoggerData[]> {
        const params: Params = {}
        params['pageNumber'] = pageNumber
        params['pageSize'] = pageSize
        params['sort'] = sort
        return this.http.get<LoggerData[]>(`${this.apiUrl}`)
    }

    logError(name: string, message: string, stack: string, params?: any) {
        const errorData = {
            name: name,
            message: message,
            params: params || {},
            stack: stack,
            time: new Date(),
            timezoneOffset: new Date().getTimezoneOffset(),
            type: 3,
        }
        return this.http.put(this.apiUrl, errorData).subscribe({
            next: () => console.log('Error logged successfully'),
            error: (err) => console.error('Failed to log error', err),
        })
    }
}
