import { Component } from '@angular/core'
import { Footer1Component } from '@main/common/footer-1/footer-1.component'
import { HeaderComponent } from '@main/common/header/header.component'

@Component({
    selector: 'app-layout-centered',
    standalone: true,
    templateUrl: './layout-centered.component.html',
    styleUrls: ['./layout-centered.component.scss'],
    imports: [HeaderComponent, Footer1Component],
})
export class LayoutCenteredComponent {}
