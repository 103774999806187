import { Injectable, inject } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { JwtService } from './jwt.service'
import { TokenStorageService } from './token-storage.service'

@Injectable({ providedIn: 'root' })
export class IsAuthenticatedGuard implements CanActivate {
    private jwtService = inject(JwtService)
    private tokenStorageService = inject(TokenStorageService)
    private router = inject(Router)

    private isLoggedIn() {
        const accessToken = this.tokenStorageService.getAccessToken()
        if (accessToken) {
            return !this.jwtService.isTokenExpired(accessToken)
        }
        return false
    }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
        const isAuth = this.isLoggedIn()
        if (!isAuth) {
            this.router.navigate(['/auth/login'], { queryParams: { returnUrl: state.url } })
        }
        return isAuth
    }
}
