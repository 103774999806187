import { CommonModule, DATE_PIPE_DEFAULT_OPTIONS } from '@angular/common'
import {
    provideHttpClient,
    withFetch,
    withInterceptors,
    withInterceptorsFromDi,
    withJsonpSupport,
    withXsrfConfiguration,
} from '@angular/common/http'
import { APP_INITIALIZER, ApplicationConfig, importProvidersFrom, isDevMode } from '@angular/core'
import { BrowserModule, provideClientHydration } from '@angular/platform-browser'
import { provideAnimations } from '@angular/platform-browser/animations'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import {
    PreloadAllModules,
    provideRouter,
    withComponentInputBinding,
    withInMemoryScrolling,
    withPreloading,
    withRouterConfig,
} from '@angular/router'
import { JwtModule } from '@auth0/angular-jwt'
import { AuthInterceptorFn } from '@core/auth/auth.interceptor'
import { LanguageInteceptorFn } from '@core/interceptors/language-inteceptor'
import { AppStateService } from '@core/states/app-state.service'
import { environment } from '@environment/environment'
import { provideTransloco } from '@jsverse/transloco'
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha'
import { TranslocoHttpLoader } from 'src/transloco-loader'
import { APP_ROUTES } from './app.routes'

const appStateInitializer = (provider: AppStateService) => {
    return () => provider.init()
}

export const appConfig: ApplicationConfig = {
    providers: [
        importProvidersFrom(CommonModule, BrowserModule, JwtModule, RecaptchaV3Module),
        {
            provide: DATE_PIPE_DEFAULT_OPTIONS,
            useValue: { dateFormat: 'shortDate' },
        },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
        provideHttpClient(
            withXsrfConfiguration({}),
            withJsonpSupport(),
            withInterceptors([AuthInterceptorFn, LanguageInteceptorFn]),
            withInterceptorsFromDi(),
        ),
        provideRouter(
            APP_ROUTES,
            withInMemoryScrolling({ scrollPositionRestoration: 'top', anchorScrolling: 'enabled' }),
            withRouterConfig({ onSameUrlNavigation: 'reload' }),
            withComponentInputBinding(),
            withPreloading(PreloadAllModules),
        ),
        {
            provide: APP_INITIALIZER,
            useFactory: appStateInitializer,
            deps: [AppStateService],
            multi: true,
        },
        provideAnimations(),
        provideAnimationsAsync(),
        provideHttpClient(withFetch()),
        provideTransloco({
            config: {
                availableLangs: ['en_US', 'sr_RS'],
                defaultLang: 'en_US',
                // Remove this option if your application doesn't support changing language in runtime.
                reRenderOnLangChange: true,
                prodMode: !isDevMode(),
            },
            loader: TranslocoHttpLoader,
        }),
        provideClientHydration(),
    ],
}
