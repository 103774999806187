import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { User } from '@core/models'
import { AnalyticsService } from '@core/services/analytics.service'
import { LocalStorageService } from '@core/services/local-storage.service'
import { SimpleStore } from '@core/store/simple-store'
import { UserApiService } from '@main/user/services/user-api.service'
import { addMinutes } from 'date-fns'
import { catchError, map, throwError } from 'rxjs'
import { AuthApiService } from './auth-api.service'
import { JwtService } from './jwt.service'
import { ACCESS_TOKEN_KEY, TokenStorageService } from './token-storage.service'

export type AuthState = {
    initialized: boolean
    isAuthenticated: boolean
    user: User | null
    accessToken: string
    refreshToken: string
    lastUpdated: Date | null
    isSuperAdmin: boolean
    availableMethods: {
        facebook: boolean
        google: boolean
        linkedin: boolean
        password: boolean
    }
}

export const initialState: AuthState = {
    initialized: false,
    isAuthenticated: false,
    user: null,
    accessToken: '',
    refreshToken: '',
    lastUpdated: null,
    isSuperAdmin: false,
    availableMethods: {
        facebook: false,
        google: false,
        linkedin: false,
        password: false,
    },
}

@Injectable({
    providedIn: 'root',
})
export class AuthStateService extends SimpleStore<AuthState> {
    private authApiService = inject(AuthApiService)
    private userApiService = inject(UserApiService)
    private localStorageService = inject(LocalStorageService)
    private refreshTokenTimeout?: NodeJS.Timeout
    private jwtService = inject(JwtService)
    private tokenStorageService = inject(TokenStorageService)
    private router = inject(Router)
    private analyticsService = inject(AnalyticsService)

    constructor() {
        super(initialState)
    }

    init() {
        const accessToken = this.tokenStorageService.getAccessToken()
        if (accessToken) {
            const unexpired = this.jwtService.getUnexpiredDecoded(accessToken)
            if (unexpired) {
                this.setStateAfterLogin(accessToken)
                return
            }

            const expiryDate = this.jwtService.getExpiryDate(accessToken)
            if (expiryDate && expiryDate > new Date()) {
                this.setStateAfterLogin(accessToken)
                return
            }
        }
        this.loadAvailableMethods()
    }

    isAuthenticated(): boolean {
        return this.getState().isAuthenticated
    }

    getUser(): User | null {
        return this.getState().user ?? null
    }

    getUserId(): string | null {
        return this.getState().user?.id ?? null
    }

    isSuperAdmin(): boolean {
        return this.getState().isSuperAdmin ?? false
    }

    login(username: string, password: string, rememberMe: boolean, recaptchaToken: string) {
        return this.authApiService.login(username, password, rememberMe, recaptchaToken).pipe(
            map((data) => {
                this.analyticsService.identify(data.subject)
                this.setStateAfterLogin(data.token)
                return data
            }),
            //             mergeMap((resp) => {
            //     return this.authApiService
            //         .authenticateWithRefreshToken(resp.token)
            //         .pipe(tap((x) => console.log(x)))
            // }),
        )
    }

    authenticateWithRefreshToken(code: string) {
        return this.authApiService.authenticateWithRefreshToken(code).pipe(
            map((resp) => {
                if (resp.token) {
                    this.setStateAfterLogin(resp.token, resp.refreshToken?.token)
                }
                return resp
            }),
            catchError((error) => {
                // this.logout()
                return throwError(() => new Error('Failed to authenticate with refresh token'))
            }),
        )
    }

    authenticateWithCode(code: string) {
        return this.authApiService.authenticateWithCode(code).pipe(
            map((resp) => {
                if (resp.token) {
                    this.setStateAfterLogin(resp.token, resp.refreshToken?.token)
                }
                return resp
            }),
            catchError((error) => {
                // this.logout()
                return throwError(() => new Error('Failed to authenticate with token'))
            }),
        )
    }

    authenticateWithGoogle(code: string) {
        return this.authApiService.authenticateWithGoogle(code).pipe(
            map((resp) => {
                if (resp.token) {
                    this.setStateAfterLogin(resp.token, resp.refreshToken?.token)
                }
                return resp
            }),

            catchError((error) => {
                // this.logout()
                return throwError(() => new Error('Failed to authenticate with Google'))
            }),
        )
    }

    setStateAfterLogin(accessToken: string, refreshToken?: string) {
        const decoded = this.jwtService.decodeToken(accessToken)

        this.setState({
            accessToken,
            refreshToken: refreshToken ?? this.getState().refreshToken,
            isAuthenticated: true,
            user: { ...decoded, id: decoded?.sub ?? decoded?.accountId } as User,
            lastUpdated: new Date(),
            initialized: true,
        })
        this.saveTokensInStorage()
        this.startRefreshTokenTimer()
    }

    logout(redirect?: string) {
        this.authApiService.logout().subscribe({
            next: () => {
                this.reset()
                this.stopRefreshTokenTimer()
                this.tokenStorageService.clear()
                this.localStorageService.removeItem(ACCESS_TOKEN_KEY)
                window.location.href = redirect ?? '/auth/login'
            },
        })
    }

    setUser(user: User) {
        this.setState({ user, lastUpdated: new Date() })
    }

    private loadAvailableMethods() {
        this.authApiService.getAvailableAuthMethods().subscribe({
            next: (methods) => {
                this.setState({
                    availableMethods: {
                        facebook: methods.includes('facebook'),
                        google: methods.includes('google'),
                        linkedin: methods.includes('linkedin'),
                        password: methods.includes('password'),
                    },
                    initialized: true,
                })
            },
            error: (error) => {
                console.error('Error getting available auth methods', error)
            },
        })
    }

    private saveTokensInStorage() {
        this.tokenStorageService.saveAccessToken(this.getState().accessToken)
        this.tokenStorageService.saveRefreshToken(this.getState().refreshToken)
    }

    private startRefreshTokenTimer() {
        const { accessToken, refreshToken } = this.getState()
        const decoded = this.jwtService.decodeToken(accessToken)
        // set a timeout to refresh the token a minute before it expires
        const inTenMin = addMinutes(new Date(), 10)
        const expires = decoded?.exp ? new Date(decoded?.exp * 1000) : inTenMin
        const timeout = expires.getTime() - Date.now() - 60 * 1000
        this.refreshTokenTimeout = setTimeout(
            // Refresh mechanism not in use. Interceptor automatically stores new token that comes with x-auth-token header
            // () => this.authenticateWithRefreshToken(refreshToken).subscribe(),
            // so just call an endpoint which will trigger the interceptor
            () => this.authApiService.getAvailableAuthMethods().subscribe(),
            timeout,
        )
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout)
    }
}
