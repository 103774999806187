<ngx-sonner-toaster position="top-right" richColors />

@switch (layoutService.currentLayout()) {
    @case (PageLayout.Default) {
        <app-layout-default>
            <router-outlet></router-outlet>
        </app-layout-default>
    }
    @case (PageLayout.Center) {
        <app-layout-centered>
            <router-outlet></router-outlet>
        </app-layout-centered>
    }
    @case (PageLayout.Checkout) {
        <app-layout-checkout>
            <router-outlet></router-outlet>
        </app-layout-checkout>
    }
    @case (PageLayout.Blank) {
        <router-outlet></router-outlet>
    }
    @default {
        <app-layout-default>
            <router-outlet></router-outlet>
        </app-layout-default>
    }
}
