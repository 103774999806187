import { HttpHandlerFn, HttpHeaders, HttpInterceptorFn, HttpRequest } from '@angular/common/http'
import { inject } from '@angular/core'
import { AppStateService } from '@core/states/app-state.service'
import { TranslocoService } from '@jsverse/transloco'

export const LanguageInteceptorFn: HttpInterceptorFn = (
    req: HttpRequest<any>,
    next: HttpHandlerFn,
) => {
    const translocoService = inject(TranslocoService)
    const selectedLanguage = translocoService.getActiveLang()
    const modifiedReq = req.clone({
        setHeaders: {
            'Accept-Language': selectedLanguage,
        },
    })
    return next(modifiedReq)
}
