import { Injectable, inject } from '@angular/core'
import { LOCAL_STORAGE, SESSION_STORAGE } from '@ng-web-apis/common'

export const ACCESS_TOKEN_KEY = 'atk'
export const REFRESH_TOKEN_KEY = 'rtk'

@Injectable({
    providedIn: 'root',
})
export class TokenStorageService {
    private sessionStorage = inject(SESSION_STORAGE)
    private localStorage = inject(LOCAL_STORAGE)

    clear() {
        this.sessionStorage?.removeItem(ACCESS_TOKEN_KEY)
        this.sessionStorage?.removeItem(REFRESH_TOKEN_KEY)
        this.localStorage?.removeItem(REFRESH_TOKEN_KEY)
        this.localStorage?.removeItem(ACCESS_TOKEN_KEY)
    }

    saveAccessToken(token: string): void {
        this.sessionStorage?.removeItem(ACCESS_TOKEN_KEY)
        this.localStorage?.removeItem(ACCESS_TOKEN_KEY)
        this.sessionStorage?.setItem(ACCESS_TOKEN_KEY, token)
        this.localStorage?.setItem(ACCESS_TOKEN_KEY, token)
    }

    saveRefreshToken(token: string): void {
        this.sessionStorage?.removeItem(REFRESH_TOKEN_KEY)
        this.localStorage?.removeItem(REFRESH_TOKEN_KEY)
        this.sessionStorage?.setItem(REFRESH_TOKEN_KEY, token)
        this.localStorage?.setItem(REFRESH_TOKEN_KEY, token)
    }

    getAccessToken(): string | null {
        return (
            this.sessionStorage?.getItem(ACCESS_TOKEN_KEY) ??
            this.localStorage?.getItem(ACCESS_TOKEN_KEY)
        )
    }

    getRefreshToken(): string | null {
        return (
            this.sessionStorage?.getItem(REFRESH_TOKEN_KEY) ??
            this.localStorage?.getItem(REFRESH_TOKEN_KEY)
        )
    }
}
