@if ((appStateService.select('selectedLanguage') | async) !== null) {
    @for (
        item of appStateService.select('availableLanguages') | async;
        track item;
        let last = $last
    ) {
        <a
            (click)="changeLanguage(item)"
            class="cursor-pointer text-white"
            [ngClass]="
                (appStateService.select('selectedLanguage') | async)?.locale === item.locale
                    ? 'font-extrabold'
                    : 'font-normal'
            "
        >
            {{ item?.displayName | capitalize }}
        </a>

        @if (!last) {
            <span class="text-white">|</span>
        }
    }
}
