import { Component } from '@angular/core'
import { ClickOutsideDirective } from '@core/directives/click-outside.directive'
import { CapitalizePipe } from '@core/pipes/capitalize.pipe'
import { TranslocoDirective } from '@jsverse/transloco'
import { DropdownArrowComponent } from '@main/common/dropdown-arrow/dropdown-arrow.component'
import { HeaderMobileComponent } from '@main/common/header/header-mobile/header-mobile.component'
import { ProfileAvatarComponent } from '@main/user/components/profile-avatar/profile-avatar.component'

@Component({
    selector: 'app-checkout-header',
    standalone: true,
    imports: [
        CapitalizePipe,
        ClickOutsideDirective,
        DropdownArrowComponent,
        HeaderMobileComponent,
        ProfileAvatarComponent,
        TranslocoDirective,
    ],
    templateUrl: './checkout-header.component.html',
    styleUrl: './checkout-header.component.scss',
})
export class CheckoutHeaderComponent {}
