import { HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { LoggerService } from '@core/services/logger.service'
import { throwError } from 'rxjs'

@Injectable({ providedIn: 'root' })
export class ErrorHandlerService {
    loggerService = inject(LoggerService)

    handleError(name: string, errorResponse: HttpErrorResponse) {
        this.loggerService.logError(
            name,
            errorResponse.message,
            errorResponse.statusText,
            errorResponse.url,
        )
        return throwError(() => errorResponse)
    }
}
