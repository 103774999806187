<nav *transloco="let t">
    <div class="space-y-6">
        <div class="w-full">
            <button
                (click)="toggleMenuOne()"
                class="inline-flex w-full cursor-pointer items-center justify-between py-2 text-white"
            >
                <span> {{ t('header.categories') }}</span>
                <app-dropdown-arrow [isOpen]="isMenu1Open()"></app-dropdown-arrow>
            </button>

            @if (isMenu1Open()) {
                <div class="block">
                    <div class="space-y-0 bg-primary-300 text-white" role="none">
                        <!-- Dropdown items -->
                        <a
                            [routerLink]="['/quizzes']"
                            class="block px-4 py-4 text-sm hover:bg-primary-200"
                            role="menuitem"
                        >
                            {{ t('header.categories.all') }}
                        </a>
                        @for (item of categoryLinks; track item) {
                            <a
                                [routerLink]="['/quizzes']"
                                class="block px-4 py-4 text-sm hover:bg-primary-200"
                                role="menuitem"
                            >
                                {{ t('header.categories.' + item) | capitalize }}
                            </a>
                        }
                    </div>
                </div>
            }
        </div>

        <div class="w-full">
            <button
                (click)="toggleMenuTwo()"
                class="inline-flex w-full cursor-pointer items-center justify-between py-2 text-white"
            >
                <span> {{ t('header.topGames') }}</span>
                <app-dropdown-arrow [isOpen]="isMenu2Open()"></app-dropdown-arrow>
            </button>

            @if (isMenu2Open()) {
                <div class="block">
                    <div class="space-y-0 bg-primary-300 text-white" role="none">
                        <!-- Dropdown items -->
                        @for (item of topGamesLinks; track item) {
                            <a
                                (click)="goToFeaturePage(item)"
                                class="block px-4 py-4 text-sm hover:bg-primary-200"
                                role="menuitem"
                            >
                                {{ t('featured.' + item) }}
                            </a>
                        }
                    </div>
                </div>
            }
        </div>

        @if (loggedIn()) {
            <div class="w-full">
                <!-- <div class="mb-8">
                <a class="text-white" [routerLink]="['game-center']">  {{ t('header.gameCenter') }} </a>
            </div> -->
                <div
                    (click)="toggleProfileMenu()"
                    class="inline-flex w-full cursor-pointer items-center justify-between text-white"
                >
                    <app-profile-avatar></app-profile-avatar>

                    <span>
                        {{ authStateService.getUser()?.firstName }}
                        {{ authStateService.getUser()?.lastName }}
                    </span>
                    <app-dropdown-arrow [isOpen]="isMenu2Open()"></app-dropdown-arrow>
                </div>

                @if (isProfileMenuOpen()) {
                    <div class="block pt-3">
                        <div class="space-y-0 bg-primary-300 text-white" role="none">
                            <!-- <a
                        [routerLink]="['profile']"
                        class="block px-4 py-4 text-sm hover:bg-primary-200"
                        role="menuitem"
                    >
                        My Account
                    </a> -->
                            <a
                                [routerLink]="['change-profile']"
                                class="block px-4 py-4 text-sm hover:bg-primary-200"
                                role="menuitem"
                            >
                                {{ t('header.profile') }}
                            </a>
                            <a
                                [routerLink]="['change-password']"
                                class="block px-4 py-4 text-sm hover:bg-primary-200"
                                role="menuitem"
                            >
                                {{ t('header.changePassword') }}
                            </a>
                            <a
                                (click)="authStateService.logout()"
                                class="block px-4 py-4 text-sm hover:bg-primary-200"
                                role="menuitem"
                            >
                                {{ t('header.logout') }}
                            </a>
                        </div>
                    </div>
                }
            </div>
        }
    </div>

    <div>
        <div [class.invisible]="loggedIn()" class="flex items-center gap-4">
            <button
                [routerLink]="['/auth', 'register']"
                class="cursor-pointer rounded bg-primary-100 px-4 py-2 text-primary-400 hover:bg-primary-50"
            >
                {{ t('auth.getStarted') }}
            </button>
            <button
                [routerLink]="['/auth', 'login']"
                class="cursor-pointer rounded bg-primary-100 px-4 py-2 text-primary-400 hover:bg-primary-50"
            >
                {{ t('auth.login') }}
            </button>
        </div>

        <app-language-selector class="mt-4"></app-language-selector>
    </div>
</nav>
