import { Language } from '@core/states/app-state.service'

export interface Money {
    amount: number
    currency: string
}

export interface PaymentInstructionIdentity {
    id: string
}

export interface Profile {
    displayName: string
    id: string
    profilePicture: string
}

export interface AccountDetail {
    firstName: string
    lastName: string
    address1: string
    address2: string
    city: string
    phoneNumber: string
    zip: string
    country: string
}

export interface DisplayImage {
    createdAt: string
    description: string
    id: string
    identity: PaymentInstructionIdentity
    location: string
    name: string
    size: number
    type: string
}

export interface IncrementalRewardConfiguration {
    endsAt: string
    incrementAmount: Money
    incrementEveryTickets: number
}

export interface ProductId {
    id: string
}

export interface Competition {
    buyIn: Money
    category: string
    createdAt: string
    createdBy: AccountId
    description: string
    displayImage: DisplayImage
    id: string
    incrementalRewardConfiguration: IncrementalRewardConfiguration
    minimumParticipants: number
    mode: string
    name: string
    numberOfQuestions: number
    order: number
    participants: number
    productId: ProductId
    rewardInMoney: Money
    rewardMode: string
    slug: string
    status: 'CLOSED' | 'ACTIVE'
    updatedAt: string
    updatedBy: AccountId
    version: number
    imageUrl: string
    top10: {
        competitionId: string
        end: Date | null
        id: string
        points: number
        start: Date | null
        ticketId: string
        userId: string
    }[]
}

export interface InvoiceCompany {
    accountNo: string
    address: string
    contactEmail: string
    logoUrl: string
    name: string
    registrationId: string
    taxId: string
}

export interface InvoiceItem {
    name: string
    amount: number
    amountType: string
    price: Money
    tax: number
    taxFree: number
    taxRate: number
}

export interface InvoiceAddressedTo {
    address: string
    country: string
    fullName: string
    phoneNumber: string
}

export interface PaymentInstructionIdentity {
    id: string
    identity: string
}

export interface Payment {
    amount: Money
    authCode: string
    clientIp: string
    details: PaymentDetails
    hash: string
    id: string
    identity: PaymentInstructionIdentity
    orderId: string
    paymentMethod: string
    paymentStatus: 'UNPAID' | 'PAID' | 'FAILED' // Enum representing payment status.
    rand: string
    transactionDate: Date
}

export interface PaymentDetails {
    authCode: string
    transactionId: string
}

export interface Invoice {
    from: InvoiceCompany
    invoiceId: string
    issuancePlace: string
    issuedAt: Date
    items: InvoiceItem[]
    payment: Payment
    tax: Money
    to: InvoiceAddressedTo
    total: Money
}

export interface Money {
    amount: number
    currency: string
}

export interface Address {
    address1: string
    address2: string
    city: string
    country: string
    firstName: string
    lastName: string
    phoneNumber: string
    zip: string
}

export interface ProductDetail {
    id: ProductId
    name: string
    price: Money
}

export interface ProductStatusDetails {
    at: string
    status: 'WIP' | 'TEST' | 'LIVE' | 'ARCHIVED'
}

export interface Product {
    category: ProductCategory // Placeholder for a category object. Define specific structure if known.
    description: string
    id: ProductId
    identity: ProductId
    meta: ProductMeta // Placeholder for metadata. Define specific structure if known.
    name: string
    price: Money
    statusDetails: ProductStatusDetails
}

export interface ProductMeta {
    _todo: string
}

export interface ProductCategory {
    _todo: string
}

export interface OrderStatus {
    at: string
    comment: string
    status: 'PROCESSING' | 'COMPLETED' | 'CANCELLED' | 'AWAITING_PAYMENT' | 'DONE' // Enum representing order status.
}

export interface Order {
    accountId: string
    deliveryDetails: Address
    id: string
    productDetails: ProductDetail[]
    products: Product[]
    status: OrderStatus
    totalPrice: Money
}

export interface OrderCreateInput {
    productIds: string[]
    deliveryDetails: Address
    acceptedTos: boolean
    acceptedPrivacyPolicy: boolean
    paymentProvider: string
    comment: string
}

export interface Answer {
    answer: string
    id: string
}

export interface AnswerResponse {
    correct: boolean
    score: number
    correctAnswers: Answer[]
}

export interface AccountId {
    id: string
}

export interface Account {
    id: string
    email: string
    picture: string
    status: 'PENDING_VERIFICATION' | 'ACTIVE' | 'ARCHIVED' | 'INACTIVE'
}

export interface Question {
    answers: QuestionOption[]
    category: string
    createdAt: string
    createdBy: AccountId
    description: string
    difficulty: number
    id: string
    question: string
    timeToAnswer: number
    type: 'ONE' | 'MANY'
    updatedAt: string
    updatedBy: AccountId
}

export interface QuestionOption extends Answer {
    correct: boolean
}

export interface Ranking {
    competitionId: string
    displayName: string
    score: number // Using `number` for double as TypeScript does not distinguish between integers and floats.
    userId: string
}

export interface AppLocale {
    country: string
    displayCountry: string
    displayLanguage: string
    displayName: string
    displayScript: string
    displayVariant: string
    extensionKeys: ExtensionKey[]
    iso3Country: string
    iso3Language: string
    language: string
    script: string
    unicodeLocaleAttributes: string[]
    unicodeLocaleKeys: string[]
    variant: string
}

export interface ExtensionKey {
    _todo: string
}

export interface Template {
    identity: PaymentInstructionIdentity
    locale: AppLocale
    template: string
    title: string
    type: string
}

export interface Ticket {
    competition?: Competition
    id: string
    orderId: string | null
    purchaseDate: Date
    play: Play
    ticketId: string
    useBy: Date
    usedDate: Date
    userId: string
    used: boolean
}

export interface Play {
    competitionId?: string
    id: string
    ticketId: string
    start: Date | null
    end: Date | null
    points: number
}

export type User = {
    id: string
    firstName: string
    lastName: string
    profilePicture: string
    scopes: string // separated by space
    active: boolean
    locked: boolean
    expired: boolean
    username: string
    accountId: string
}

export type DecodedAccessToken = {
    sub: string
    exp: number
    iat: number
}

export type AccessTokenContent = User & DecodedAccessToken

export type LoginResponse = { token: string; refreshToken?: { token: string }; [key: string]: any }

export interface AnswerScore {
    correct: boolean
    score: number
}

export interface AuthResponse {
    expiresAt: Date
    issuedAt: Date
    subject: string
    token: string
}

export enum TicketState {
    USED = 0,
    PLAYABLE = 1,
    EXPIRED = 2,
}

export type AppReferenceData = {
    'product.defaultCurrency': string
    'competition-category': string[]
    countries: { name: string; code: string }[]
    'available-languages': Language[]
}

export interface Review {
    name: string
    image: string
    review: string
}

export interface TrendingCompetitionsByLanguage {
    [key: string]: string[]
}

export interface ReviewsByLanguage {
    [key: string]: Review[]
}
