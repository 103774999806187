<!-- Heroicon name: solid/chevron-down -->
@if (!isOpen()) {
    <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
    >
        <path
            fill-rule="evenodd"
            d="M10 12.586l-4.293-4.293a1 1 0 10-1.414 1.414l5 5a1 1 0 001.414 0l5-5a1 1 0 00-1.414-1.414L10 12.586z"
            clip-rule="evenodd"
        ></path>
    </svg>
}
<!-- Heroicon name: solid/chevron-up -->
@if (isOpen()) {
    <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
    >
        <path
            fill-rule="evenodd"
            d="M10 7.414l4.293 4.293a1 1 0 01-1.414 1.414L10 10.414l-3.293 3.293a1 1 0 01-1.414-1.414L10 7.414z"
            clip-rule="evenodd"
        ></path>
    </svg>
}
