export const environment = {
    production: true,
    serviceUrl: 'https://api.test.quizzaro.com',
    google: {
        clientId: '202473567396-q6uktkf2tk03o49u4ctd2iupjk17rsjo.apps.googleusercontent.com',
        redirectUrl: 'https://test.quizzaro.com/auth/google',
    },
    recaptchaSiteKey: '6LevMAQqAAAAAJIm3isrYKWQ_Hut95L6jQzvRRnj',
    siteTitle: 'Quizzaro',
    paymentProvider: 'FAKE',
    siteUrl: 'https://test.quizzaro.com',
}
