import { CanDeactivateFn } from '@angular/router'
import { Observable } from 'rxjs'

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean>
}

export const leavePageGuard: CanDeactivateFn<ComponentCanDeactivate> = (
    component: ComponentCanDeactivate,
) => {
    return component.canDeactivate ? component.canDeactivate() : true
}
