import { Injectable } from '@angular/core'
import { JwtHelperService } from '@auth0/angular-jwt'
import { AccessTokenContent, DecodedAccessToken, User } from '@core/models'

@Injectable({
    providedIn: 'root',
})
export class JwtService {
    private jwtHelper = new JwtHelperService()

    decodeToken(token: string): AccessTokenContent | null {
        return this.jwtHelper.decodeToken(token)
    }

    isTokenExpired(token: string) {
        return this.jwtHelper.isTokenExpired(token)
    }

    getExpiryDate(token: string): Date | null {
        const decoded = this.decodeToken(token)
        if (!decoded) return null
        return new Date(decoded.exp * 1000)
    }

    getUnexpiredDecoded(token: string): AccessTokenContent | null {
        if (!token) return null
        if (this.isTokenExpired(token)) return null
        return this.decodeToken(token)
    }
}
