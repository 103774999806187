import { isPlatformBrowser } from '@angular/common'
import { Injectable, PLATFORM_ID, inject } from '@angular/core'
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router'

@Injectable({
    providedIn: 'root',
})
export class LocalStorageService {
    private platformId = inject(PLATFORM_ID)

    #storage: Storage | null = null

    constructor() {
        if (isPlatformBrowser(this.platformId)) {
            this.#storage = localStorage
        }
    }

    setItem(key: string, value: string): void {
        this.#storage?.setItem(key, value)
    }

    getItem(key: string): string | null {
        return this.#storage?.getItem(key) ?? null
    }

    removeItem(key: string) {
        this.#storage?.removeItem(key)
    }
}

export const resolveDataSync = (data: any): ResolveFn<any> => {
    return (_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
        return data
    }
}
