import { Component } from '@angular/core'
import { TranslocoDirective } from '@jsverse/transloco'
import { LanguageSelectorComponent } from '@main/common/language-selector/language-selector.component'
import { FacebookLinkIconComponent } from '../facebook-link-icon/facebook-link-icon.component'
import { InstagramLinkIconComponent } from '../instagram-link-icon/instagram-link-icon.component'
import { TwitterLinkIconComponent } from '../twitter-link-icon/twitter-link-icon.component'
import { YoutubeLinkIconComponent } from '../youtube-link-icon/youtube-link-icon.component'

@Component({
    selector: 'app-footer-1',
    standalone: true,
    templateUrl: './footer-1.component.html',
    styleUrl: './footer-1.component.scss',
    imports: [
        FacebookLinkIconComponent,
        InstagramLinkIconComponent,
        YoutubeLinkIconComponent,
        TwitterLinkIconComponent,
        TranslocoDirective,
        LanguageSelectorComponent,
    ],
})
export class Footer1Component {}
