import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { ErrorHandlerService } from '@core/services/error-handler.service'
import { environment } from '@environment/environment'
import { Observable, catchError } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class UserApiService {
    private http = inject(HttpClient)
    private apiUrl = `${environment.serviceUrl}/accounts`
    private errorHandlerService = inject(ErrorHandlerService)

    getMe(): Observable<{ displayName: string; id: string; profilePicture: string }> {
        return this.http
            .get<{ displayName: string; id: string; profilePicture: string }>(
                `${this.apiUrl}/my-profile`,
            )
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.errorHandlerService.handleError('Users', err)
                }),
            )
    }

    changeProfile(data: {
        firstName: string
        lastName: string
        username: string
    }): Observable<void> {
        return this.http.put<void>(`${this.apiUrl}/profile`, data).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Users', err)
            }),
        )
    }

    uploadProfilePicture(userId: string, file: File): Observable<{ location: string }> {
        const formData = new FormData()
        formData.append('file', file)
        return this.http
            .post<{ location: string }>(`${this.apiUrl}/profile-picture?id=${userId}`, formData)
            .pipe(
                catchError((err: HttpErrorResponse) => {
                    return this.errorHandlerService.handleError('Users', err)
                }),
            )
    }
}
