import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable, inject } from '@angular/core'
import { AppReferenceData } from '@core/models'
import { ErrorHandlerService } from '@core/services/error-handler.service'
import { environment } from '@environment/environment'
import { Observable, catchError } from 'rxjs'

@Injectable({
    providedIn: 'root',
})
export class HomeApiService {
    private http = inject(HttpClient)
    private errorHandlerService = inject(ErrorHandlerService)

    private apiUrl = `${environment.serviceUrl}`

    getReferenceData(): Observable<AppReferenceData> {
        return this.http.get<AppReferenceData>(`${this.apiUrl}/reference-data/`).pipe(
            catchError((err: HttpErrorResponse) => {
                return this.errorHandlerService.handleError('Reference Data', err)
            }),
        )
    }
}
