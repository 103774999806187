import { Component } from '@angular/core'
import { RouterLink } from '@angular/router'
import { ClickOutsideDirective } from '@core/directives/click-outside.directive'
import { CapitalizePipe } from '@core/pipes/capitalize.pipe'
import { TranslocoDirective } from '@jsverse/transloco'
import { HeaderDirective } from '@main/common/header/header.directive'
import { ProfileAvatarComponent } from '../../../user/components/profile-avatar/profile-avatar.component'
import { ButtonComponent } from '../../button/button.component'
import { DropdownArrowComponent } from '../../dropdown-arrow/dropdown-arrow.component'
import { LanguageSelectorComponent } from '../../language-selector/language-selector.component'

@Component({
    selector: 'app-header-mobile',
    standalone: true,
    imports: [
        ButtonComponent,
        RouterLink,
        LanguageSelectorComponent,
        DropdownArrowComponent,
        ClickOutsideDirective,
        CapitalizePipe,
        TranslocoDirective,
        ProfileAvatarComponent,
    ],
    templateUrl: './header-mobile.component.html',
    styleUrl: './header-mobile.component.scss',
})
export class HeaderMobileComponent extends HeaderDirective {}
